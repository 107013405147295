<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reclamaciones')+': '+$t('Salud')}} </h3>
		</div>
		<div class="p-col-12" v-if="!mostrar">
			<div class="card">
                <DataTable :value="claims" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
                    <template #header>
                        <div class="table-header">
                            <Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Filtrar()"></Dropdown>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 100px">
                        <template #body="slotProps">
							<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'es'">
								{{slotProps.data.estado_nombre}}
							</strong>
							<strong :style="'color:'+slotProps.data.color" v-if="i18n.locale() == 'en'">
								{{slotProps.data.estado_name}}
							</strong>
                        </template>
                    </Column>   
                    <Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                              
                        </template>
                    </Column>
                    <Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.poliz}}                              
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fecha)}}
                        </template>
                    </Column>
					<Column :header="$t('Acciones')" headerStyle="width: 80px">
						<template #body="slotProps">
							<Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="Editar(slotProps.data)" v-if="edit && slotProps.data.forence"/> 
							<Button :disabled ="$store.state.loading" icon="pi pi-eye" class="p-button-rounded p-button-info p-mr-2" @click="Editar(slotProps.data)" v-if="edit && !slotProps.data.forence"/> 
							<Tag class="p-mr-2" icon="pi pi-exclamation-triangle" severity="warning" :value="$t('Pendiente')" v-if="slotProps.data.forence"></Tag>
						</template>
					</Column>

                </DataTable>
			</div>
        </div>
		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Póliza')}}</h4>
					</template>
					<template #content>
						<div class="p-grid">
							<div class="p-col-6">
								{{$t('Número')}}: <strong>{{seleccion.poliz.codigo+seleccion.poliz.renovacion}}</strong>			
							</div>
							<div class="p-col-3" v-if="i18n.locale() == 'es'">
								<strong>{{seleccion.salud.tipopoliza_nombre}}</strong> 			
							</div>
							<div class="p-col-3" v-else>
								<strong>{{seleccion.salud.tipopoliza_name}}</strong> 			
							</div>
							<div class="p-col-3" v-if="i18n.locale() == 'es'">
								<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" style="background-color:yellow; color: #ffffff" v-if="seleccion.poliz.estado==4"/>
								<Avatar icon="bi bi-shield-check" class="p-mr-2" style="background-color:green; color: #ffffff" v-if="seleccion.poliz.estado==5"/>
								<Avatar icon="bi bi-shield-slash" class="p-mr-2" style="background-color:red; color: #ffffff" v-if="seleccion.poliz.estado==6"/>
								<strong>{{seleccion.poliz.estado_nombre}}</strong> 			
							</div>
							<div class="p-col-3" v-else>
								<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" style="background-color:yellow; color: #ffffff" v-if="seleccion.poliz.estado==4"/>
								<Avatar icon="bi bi-shield-check" class="p-mr-2" style="background-color:green; color: #ffffff" v-if="seleccion.poliz.estado==5"/>
								<Avatar icon="bi bi-shield-slash" class="p-mr-2" style="background-color:red; color: #ffffff" v-if="seleccion.poliz.estado==6"/>
								<strong>{{seleccion.poliz.estado_name}}</strong> 			
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Información Básica')}}</h4>
					</template>
					<template #content>
						<div class="p-grid">
							<div class="p-col-3">
								{{$t('Número ID')}}: <strong>{{seleccion.code}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Localidad')}}: <strong>{{seleccion.salud.localidadn}}</strong>			
							</div>
							<div class="p-col-6">
								{{$t('Nombre Completo')}}: <strong>{{seleccion.nombre}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Teléfono móvil')}}: <strong>{{seleccion.celular}}</strong>			
							</div>
							<div class="p-col-3">
								{{$t('Teléfono privado')}}: <strong>{{seleccion.tlflocal}}</strong>			
							</div>
							<div class="p-col-6">
								{{$t('Correo')}}: <strong>{{seleccion.correo}}</strong>			
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<Accordion>
				<AccordionTab :header="$t('Beneficiario')+' '+(index+1)" v-for="(item, index) in seleccion.beneficiarios" :key="index">
					<h4>{{$t('Información Básica')}}</h4>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-col-12">
							<div class="p-grid">
								<div class="p-col-3">
									{{$t('Número ID')}}: <strong>{{item.code}}</strong> 			
								</div>
								<div class="p-col-9">
									{{$t('Nombre Completo')}}: <strong>{{item.nombre}}</strong> 			
								</div>
								<div class="p-col-3">
									{{$t('Teléfono móvil')}}: <strong>{{item.celular}}</strong> 			
								</div>
								<div class="p-col-3">
									{{$t('Teléfono privado')}}: <strong>{{item.tlflocal}}</strong> 			
								</div>
								<div class="p-col-6">
									{{$t('Correo')}}: <strong>{{item.correo}}</strong> 			
								</div>
							</div>
						</div>
						<br><br>
						<div class="p-col-12">
							<h4>{{$t('Forence')}}</h4>
							<div class="p-fluid p-formgrid p-grid">					
								<div class="p-field p-col-12">
									<div class="p-fluid p-formgrid p-grid">
										<div class="p-field p-col-12">
											<label>{{$t('Observaciones')}}: </label>
											<Textarea v-model="item.observacion" rows="3" :disabled="seleccion.forence==false"/>
											<small v-show="validationErrors.observacion" class="p-error">{{$t('required')}}</small>
										</div>
									</div>
								</div>
								<div class="p-field p-col-8">
									<div class="p-fluid p-formgrid p-grid">
										<div class="p-field p-col-12"> 
											<DataTable :value="item.foren" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
													:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
												<Column :header="$t('Descripción')">
													<template #body="slotProps">
														<InputText v-model="slotProps.data.descripcion" type="text" :disabled="seleccion.forence==false"/>
													</template>
												</Column>
												<Column :header="$t('Costo')">
													<template #body="slotProps">
														<InputText v-model="slotProps.data.costo" type="number" min="0" style="max-width:100px;" @change="CalcularSub(index)" :disabled="seleccion.forence==false"/>
													</template>
												</Column>
												<Column :header="$t('Eliminar')">
													<template #body="slotProps">
														<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="Delete(slotProps.data,index)"  v-if="seleccion.forence==true" :disabled="seleccion.forence==false || $store.state.loading"/>
													</template>
												</Column>
											</DataTable>
											<small v-show="validationErrors.licencia && submitted" class="p-error">{{$t('required')}}</small>
											<Button :disabled ="$store.state.loading" :label="$t('Agregar')" icon="pi pi-plus-circle" class="p-button-secondary"  style="max-width:150px;" @click="Agregar(index)"/>
										</div>
										<div class="p-col-6">
											<div class="p-fluid p-formgrid p-grid">
												<div class="p-col-10">
													<strong>Subtotal:</strong> 			
												</div>
												<div class="p-col-2">
													<strong>{{item.subtotal}}</strong> 			
												</div>
												<br><br>
												<div class="p-col-6">
													<strong>{{$t('Impuesto')}}:</strong> 	
												</div>
												<div class="p-col-4">
													{{parseFloat(item.impuesto).toFixed(2)}}%
												</div>
												<div class="p-col-2	">
													<strong>{{(parseFloat(item.subtotal)*parseFloat(item.impuesto/100)).toFixed(2)}}</strong> 			
												</div>
												<br><br>
												<div class="p-col-10">
													<h3><strong>{{$t('Total')}}:</strong></h3>
												</div>
												<div class="p-col-2">
													<h3><strong>{{(parseFloat(item.subtotal)*parseFloat(1+item.impuesto/100)).toFixed(2)}}</strong></h3>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="p-field p-col-4" style="background:#E7E7D9;">
									<h5>{{$t('Cargar Documentos')}}</h5>
									<div class="p-fluid p-formgrid p-grid">
										<div class="p-field p-col-12"  v-if="seleccion.forence==true">
											<label>{{$t('Descripción')}}</label>
											<InputText v-model="descripcion" type="text"/>
											<small v-show="validationErrors.descripcion" class="p-error">{{$t('required')}}</small>
										</div>
										<div class="p-field p-col-6"  v-if="seleccion.forence==true"> 
											<FileUpload :chooseLabel="$t('Subir JPG,JPEG')" uploadLabel="Cargar" mode="basic" :name="img" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader1(img,index)" :auto="true"/>
											<small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
										</div>
										<div class="p-field p-col-6"  v-if="seleccion.forence==true"> 
											<FileUpload :chooseLabel="$t('Subir PDF')" uploadLabel="Cargar" mode="basic" :name="pdf" :url="url.api+'upload.php'"  accept="application/pdf" :maxFileSize="4000000" @upload="myUploader2(pdf,index)" :auto="true"/>
											<small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
										</div>
										<div class="p-field p-col-12"> 
											<DataTable :value="item.anexos" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
													:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
												<Column :header="$t('Descripción')">
													<template #body="slotProps">
														{{slotProps.data.descripcion}}
													</template>
												</Column>
												<Column :header="$t('Ver')">
													<template #body="slotProps">
														<img style="width: 25px;" :src="url.upload+slotProps.data.codigo" v-if="slotProps.data.format=='jpg'"  @click="IMG(url.upload+slotProps.data.codigo)"/>
														<img style="width: 25px;" src="images/pdf.jpg" v-if="slotProps.data.format=='pdf'" @click="VerPDF(slotProps.data.codigo)"/>
													</template>
												</Column>
												<Column :header="$t('Eliminar')">
													<template #body="slotProps">
														<Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="DeleteDoc(slotProps.data,index)"  v-if="seleccion.forence==true"/>
													</template>
												</Column>
											</DataTable>
											<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
												<img style="width: 350px;" :src="imagen"/>
											</OverlayPanel>										
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</AccordionTab>
			</Accordion>
		</div>

		<div class="p-col-12 p-md-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h4>{{$t('Estatus')}}:  
							<strong :style="'color:'+seleccion.color" v-if="i18n.locale() == 'es'">
								{{seleccion.estado_nombre}}
							</strong>
							<strong :style="'color:'+seleccion.color" v-if="i18n.locale() == 'en'">
								{{seleccion.estado_name}}
							</strong>
						</h4>
					</template>
					<template #content>					
						<div class="p-fluid p-formgrid p-grid">						
							<div class="p-field p-col-2">
								{{$t('Observaciones')}}: 
							</div>
							<div class="p-field p-col-10">
								{{seleccion.observacion}}
							</div>
						</div>
					</template>
					<template #footer>
						<Button :disabled ="$store.state.loading" :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary p-mr-2 p-mb-2" @click="Guardar(true)"  v-if="seleccion.forence==true"/>
						<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger p-mr-2 p-mb-2" @click="mostrar=false"/>
						<Button :disabled ="$store.state.loading" :label="$t('Procesar')" icon="pi pi-check" class="p-button-infor p-mr-2 p-mb-2" @click="Guardar(false)"  v-if="seleccion.forence==true"/>
					</template>
				</Card>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Reclamos','Claim3');
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import url from "@/service/_URL";

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: {nombre: 'Todos', name: 'All', code: 0, color: ''},
				opciones: [{nombre: 'Todos', name: 'All', code: 0, color: ''}],
				resultado: [],
				insert: false,
				edit: false,
				del: false,
				poliza: null,
				filters: {},
				selectedCustomers: null,
				submitted: false,
				size: '60vw',
				polizas: [],
				seleccion: {},
				mostrar: false,
				forences: [],
				estados: [],
				validationErrors: {},
				informacion: {
					observacion: null,
					impuesto: 0,
				},
				descripcion: null,
				estado: null,
				claims: [],
				anexos: [],
				img: null,
				pdf: null,
				foren: [],
				imegan: null,
				subtotal: 0,
				total: 0,
				impuesto: 0,
			}
		},
		created() {
			this.url = url;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			const tokgen = new Token(256);
			this.img = tokgen.generate();
			this.pdf = tokgen.generate();
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.asunto = [];
				this.polizas = [];
				this.anexos = [];
				this.mostrar = false;
				Consulta.Ini().then(result => {
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.polizas = result.consult.polizas;
					this.asunto = result.consult.asunto;
					this.forences = result.consult.forences;
					if (result.consult.estados){
						this.estados = result.consult.estados;
					} 
					if (result.consult.claimsforence){
						this.claims = result.consult.claimsforence;
						this.resultado = result.consult.claimsforence;
					} 
					if (result.consult.opciones){
						result.consult.opciones.forEach(result => {
							this.opciones.push(result);
						});
					}
				}); 
			},
			Filtrar() {		
				if (this.opcion.code==0){
					this.claims = this.resultado;
				} else {
					this.claims = this.resultado.filter(val => val.estado == this.opcion.code);			
				}
			},
			Agregar(index) {
				this.seleccion.beneficiarios[index].foren.push({id: 0, descripcion:  '', costo: 0 , cantidad: 1});
			},
			CalcularSub(index){
				this.seleccion.beneficiarios[index].subtotal = 0;
				this.seleccion.beneficiarios[index].foren.forEach(element => {
					this.seleccion.beneficiarios[index].subtotal = (parseFloat(this.seleccion.beneficiarios[index].subtotal) + parseFloat(element.costo*element.cantidad)).toFixed(2);
				});
			},
			Editar(data) {
				this.seleccion = {};
				Consulta.Procesar('MostFor',{
					id: data.id,
					}).then(response => {
						if (response.result.claimsforence){
							this.seleccion = response.result.claimsforence;
							this.informacion = {
								observacion: data.observacion,
								impuesto: this.impuesto,
							};
							this.mostrar = true;
						}
					});
			},
			Delete(selec,index) {
				this.seleccion.beneficiarios[index].foren = this.seleccion.beneficiarios[index].foren.filter(val => val !== selec);
				this.CalcularSub(index);
			},
			DeleteDoc(selec,index) {
				this.seleccion.beneficiarios[index].anexos = this.seleccion.beneficiarios[index].anexos.filter(val => val !== selec);
			},
			myUploader1(img,index) {
				if (this.descripcion == null || this.descripcion == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Descripción'), life: 10000});
				} else {
					Consulta.Procesar('RevFoto',{
						foto: img,
						descripcion: this.descripcion,
						claim: this.seleccion.id,
					}).then(response => {
						if(response.result){					
							this.seleccion.beneficiarios[index].anexos.push({
								id: 0, 
								claim: this.seleccion.id,
								descripcion:  this.descripcion, 
								format: 'jpg', 
								codigo: response.result
							});
							this.descripcion = null;
							const tokgen = new Token(256);
							this.img = tokgen.generate();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error en Foto'), 
								life: 3000
							});
						}
					});
				}
			},
			myUploader2(pdf,index) {
				if (this.descripcion == null || this.descripcion == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Descripción'), life: 10000});
				} else {
					Consulta.Procesar('RevPDF',{
						foto: pdf,
						descripcion: this.descripcion,
						claim: this.seleccion.id,
					}).then(response => {
						if(response.result){					
							this.seleccion.beneficiarios[index].anexos.push({
								id: 0, 
								claim: this.seleccion.id,
								descripcion:  this.descripcion, 
								format: 'pdf', 
								codigo: pdf
							});
							this.descripcion = null;
							const tokgen = new Token(256);
							this.pdf = tokgen.generate();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error en Foto'), 
								life: 3000
							});
						}
					});
				}
			},
			Guardar(forence) {
					Consulta.Procesar('Forence',{
						claim: this.seleccion,
						informacion: this.informacion,
						foren: this.foren,
						forence: forence,
						subtotal: parseFloat(this.subtotal).toFixed(2),
						total: (parseFloat(this.subtotal)*parseFloat(1+this.impuesto/100)-parseFloat(this.informacion.descuento)+parseFloat(this.informacion.trabajo)+parseFloat(this.informacion.pintura)+parseFloat(this.informacion.dias)*parseFloat(this.timeloss)).toFixed(2)
					}).then(response => {
						if (response.result) {
							this.$toast.add({
								severity:'success',
								summary: this.$t('Éxito'), 
								detail: this.$t('Información Procesada'), 
								life: 3000
							});
							this.Mostrar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: this.$t('Advertencia'), 
								detail: this.$t('Error'), 
								life: 3000
							});
						}
					});
			},
			IMG(imegan){
				this.imagen = imegan;
				this.toggle1(event);
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},
			VerPDF(document){
				window.open(url.upload+document+'.pdf', '_blank');
			},
			validateForm() {
				if (this.informacion.taller==null)
				this.validationErrors['taller'] = true;
				else
				delete this.validationErrors['taller'];

				return !Object.keys(this.validationErrors).length;
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
